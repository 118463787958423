import request from '@/service/request'
// 纸张尺寸数据
export function getPaperSize(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressLabelSize/list/',
    method: 'post',
    data
  })
}
// 城市数据
export function getCityData(data = {}) {
  return request({
    url: '/externaladmin/systemService/administrativeArea/complexList',
    method: 'post',
    data
  })
}

// 发送启用物流编辑
export function sendInfo(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressAccountShippingMethod/complexUpdate/',
    method: 'post',
    data
  })
}

/**
 * 根据公司id获取账号
 * @param {object} data.companyId 公司id
 * @returns
 */
export function getAccountById(data) {
  return request({
    url: '/externaladmin/systemService/expressAccount/list',
    method: 'post',
    data
  })
}

// 创建启用物流编辑
export function complexCreate(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressAccountShippingMethod/complexCreate/',
    method: 'post',
    data
  })
}
// 请求运费模板
export function getFreightTemplate(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplate/list/',
    method: 'post',
    data
  })
}

// 根据id获得一些数据（国际国内。。//是否显示跟踪号）
export function getSomeNews(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressCompany/getById/',
    method: 'post',
    data
  })
}
// 根据信息表Id获取数据
export function getNewsById(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressAccountShippingMethod/getById/',
    method: 'post',
    data
  })
}
// 启用物流渠道
export function changeChannelIsEnabled(data) {
  return request({
    url: '/externaladmin/systemService/expressShippingMethod/toEnable',
    method: 'post',
    data
  })
}
export function disi(data) {
  return request({
    url: '/externaladmin/systemService/expressAccount/dSFAuthorizeCode',
    method: 'post',
    data
  })
}
